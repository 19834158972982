import saveAs from 'file-saver';
import axios from "axios";

export async function generateExcelForProfile(item) {
	try {
		const result = await axios.get(process.env.VUE_APP_API_BASE_URL + "/backoffice/profile/" + item._id + "/generate-excel", {
			headers: {
				'X-User-Token': localStorage.getItem("BACKOFFICE_TOKEN")
			}
		});
		// console.log("RESULT = ", result.data);
		// let data = new Blob([result.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
		const data = b64toBlob(result.data, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
		const filename = item.firstname + "-" + item.lastname + ".xlsx";
		saveAs(data, filename);

		return {
			message: "Le fichier Excel a été uploadé."
		};
	}
	catch(err) {
		console.log("Error generateExcelForProfile: ", err);
		throw "Problème. Le fichier excel n'a pu être généré.";
	}
}

const b64toBlob = (b64Data, contentType='', sliceSize=512) => {
	const byteCharacters = atob(b64Data);
	const byteArrays = [];

	for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
		const slice = byteCharacters.slice(offset, offset + sliceSize);

		const byteNumbers = new Array(slice.length);
		for (let i = 0; i < slice.length; i++) {
			byteNumbers[i] = slice.charCodeAt(i);
		}

		const byteArray = new Uint8Array(byteNumbers);
		byteArrays.push(byteArray);
	}

	const blob = new Blob(byteArrays, {type: contentType});
	return blob;
}
